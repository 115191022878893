html,
[data-theme=light] {
  --btn-primary: #409eff !important;
}
html .main-section header.navbar.navbar-expand.sticky-top,
[data-theme=light] .main-section header.navbar.navbar-expand.sticky-top {
  box-shadow: 0 2px 2px #cdcdcd;
}

[data-theme=dark] {
  --btn-primary: #409eff !important;
}
[data-theme=dark] .main-section header.navbar.navbar-expand.sticky-top {
  box-shadow: 0px 2px 2px rgb(50, 50, 50);
}